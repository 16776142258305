<div>
  <div class="header">
    <div class="left">
      <a class="link" routerLink="/profile">My Profile</a>
      <a *ngIf="management" class="link" routerLink="/manager-view">My Employees</a>
      <a *ngIf="hr" class="link" routerLink="/human-resources">Human Resources</a>
    </div>
    <div class="right">
      <div class="small">
        <img src="../assets/Sycamore HR.png" class="icon">
      </div>
      <hr class="small" style="margin-top: 50px;">
      <img src="../assets/Sycamore HR.png" class="icon large">
      <div style="display: flex; justify-content: center;" class="small-login">
        <p *ngIf="currentEmployee && currentEmployee.employeeId" style="margin-right: 25px; width: 150px; margin-top: 65px;"><strong>{{ currentEmployee.firstName + " " + currentEmployee.lastName }}</strong></p>
        <div class="sign-in" style="display: flex; justify-content: center; margin-top: 50px; background-color: rgb(203, 57, 4); color: white ; border-radius: 12%; height: 60px; cursor: pointer; width: 150px;; margin-right: 50px; padding-right: 15px;" (click)="logAction()">
          <img src="../assets/smallManIcon.webp" style="height: 45px;">
          <p *ngIf="currentEmployee && currentEmployee.employeeId" style="padding-top: 15px;"><strong>Sign Out</strong></p>
          <p *ngIf="!currentEmployee || !currentEmployee.employeeId" style="padding-top: 15px;"><strong>Sign In</strong></p>
        </div>
      </div>
    </div>
  </div>
  
  <div style="position: relative;">
    <router-outlet></router-outlet>
  </div>
</div>
