import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardGuard } from 'src/helpers/auth-guard.guard';
import { BlockLoginGuard } from 'src/helpers/block-login.guard';
import { RequestConfirmGuard } from 'src/helpers/request-confirm.guard';
import { RequestGuard } from 'src/helpers/request.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./components/login/login-page/login-page.module').then(m => m.LoginPageModule)
  },
  {
    path: 'account-locked',
    loadChildren: () => import('./components/login/blocked-login/blocked-login.module').then(m => m.BlockedLoginModule),
    canActivate: [BlockLoginGuard]
  },
  {
    path: 'request-account',
    loadChildren: () => import('./components/requests/request-account/request-account.module').then(m => m.RequestAccountModule),
    canActivate: [RequestGuard]
  },
  {
    path: 'request-confirmation',
    loadChildren: () => import('./components/requests/request-confirmation/request-confirmation.module').then(m => m.RequestConfirmationModule),
    canActivate: [RequestConfirmGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./components/views/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'manager-view',
    loadChildren: () => import('./components/views/manager-view/manager-view.module').then(m => m.ManagerViewModule),
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'human-resources',
    loadChildren: () => import('./components/views/human-resources/human-resources.module').then(m => m.HumanResourcesModule),
    canActivate: [AuthGuardGuard]
  },
  {
    path: '',
    loadChildren: () => import('./components/views/profile/profile.module').then(m => m.ProfileModule),
    pathMatch: 'full',
    canActivate: [AuthGuardGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
