import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (sessionStorage.getItem('TOKEN')) {
        let token: any = decode(sessionStorage.getItem('TOKEN') + "");
        let startTime = new Date(sessionStorage.getItem('START_TIME') + "");
        let timeDiff = token.exp - token.iat;
        let expireDate = new Date(new Date(startTime).setSeconds(new Date(startTime).getSeconds() + timeDiff));
        if (expireDate > new Date()) {
          return true;
        } else {
          sessionStorage.removeItem('TOKEN');
          sessionStorage.removeItem('START_DATE');
          sessionStorage.setItem("RELOAD", "TRUE");
          window.location.href = environment.frontEndApp + '/login';
          return false;
        }
      } else {
        this.router.navigateByUrl('login');
      }
    return false;
  }
  
}
