import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Employee } from "src/models/employee.model";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn: 'root'
})
export class EmployeeService {
    constructor(private httpClient: HttpClient) {}

    private API_BASE_URL = environment.apiUrl;

    getEmployeeList(): Observable<Employee[]> {
        let headers = new HttpHeaders().set("Authorization", JSON.stringify(sessionStorage.getItem('TOKEN')));
        return this.httpClient.get<Employee[]>(this.API_BASE_URL + '/api/employee/all', {headers: headers});
    }

    getEmployeeById(employeeId: number): Observable<Employee> {
        let headers = new HttpHeaders().set("Authorization", JSON.stringify(sessionStorage.getItem('TOKEN')));
        return this.httpClient.get<Employee>(this.API_BASE_URL + '/api/employee/id', {params: {employeeId: (employeeId + "")}, headers});
    }

    getEmployeesByManager(managerId: number): Observable<Employee[]> {
        let headers = new HttpHeaders().set("Authorization", JSON.stringify(sessionStorage.getItem('TOKEN')));
        return this.httpClient.get<Employee[]>(this.API_BASE_URL + '/api/employee/manager-id', {params: {managerId: managerId + ""}, headers});
    }

    getEmployeesByDepartment(departmentId: number): Observable<Employee[]> {
        let headers = new HttpHeaders().set("Authorization", JSON.stringify(sessionStorage.getItem('TOKEN')));
        return this.httpClient.get<Employee[]>(this.API_BASE_URL + '/api/employee/department-id', {params: {departmentId: departmentId + ""}, headers})
    }

    createEmployee(employee: Employee) {
        let headers = new HttpHeaders().set("Authorization", JSON.stringify(sessionStorage.getItem('TOKEN')));
        return this.httpClient.post<Employee>(this.API_BASE_URL + '/api/employee', {params: {employee, Authorization: sessionStorage.getItem('TOKEN')}, headers});
    }

    updateEmployee(employee: Employee) {
        let headers = new HttpHeaders().set("Authorization", JSON.stringify(sessionStorage.getItem('TOKEN')));
        return this.httpClient.put<Employee>(this.API_BASE_URL + '/api/employee', {params: {employee, Authorization: sessionStorage.getItem('TOKEN')}, headers});
    }

    deleteEmployee(employeeId: number) {
        let headers = new HttpHeaders().set("Authorization", JSON.stringify(sessionStorage.getItem('TOKEN')));
        return this.httpClient.delete<Employee>(this.API_BASE_URL + '/api/employee/employee-id', {params: {employeeId: employeeId + ""}, headers});
    }
}