import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Employee } from 'src/models/employee.model';
import { EmployeeService } from 'src/services/employee.service';
import decode from 'jwt-decode';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'client';
  public currentEmployee!: Employee;
  management: boolean = false;
  hr: boolean = false;
  
  constructor(private router: Router, private employeeService: EmployeeService) {}

  ngOnInit() {
    if (sessionStorage.getItem('TOKEN')) {
      let token: string = sessionStorage.getItem('TOKEN') + "";
      let decodedToken: any = decode(token);
      this.currentEmployee = decodedToken.employee;
      this.checkManagement();
      this.checkHR();
    }
  }

  checkManagement() {
    this.employeeService.getEmployeesByManager(parseInt(this.currentEmployee.employeeId + "")).subscribe((result: any)=>{
      if (result) {
        if (result.length) {
          this.management = true;
        }
      }
    });
  }

  checkHR() {
    if (this.currentEmployee.departmentId == 1) {
      this.hr = true;
    }
  }

  logAction() {
    if (sessionStorage.getItem('TOKEN')) {
      sessionStorage.removeItem('TOKEN');
      this.hr = false;
      this.management = false;
      this.currentEmployee.employeeId = 0;
    }
    this.router.navigateByUrl('login');
  }
}
